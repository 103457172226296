import { userApi } from "context/user";
import { IHrAdvisorCandidatesResponse, IHrAdvisorCandidatesVariables, IHrAdvisorCompaniesResponse, IHrAdvisorCompaniesVariables, IHrAdvisorCompanyResponse, IHrAdvisorCompanyVariables, IHrAdvisorDiscoverFormResponse, IHrAdvisorDiscoverFormVariables, IHrAdvisorDiscoverFormsResponse, IHrAdvisorDiscoverFormsVariables, hrAdvisor_candidates, hrAdvisor_companies, hrAdvisor_company, hrAdvisor_discoverForm, hrAdvisor_discoverForms } from "./queries";
import { IHrAdvisorUpdateDiscoverFormResponse, IHrAdvisorUpdateDiscoverFormVariables, hrAdvisor_updateDiscoverForm } from "./mutations";
import { hrAdvisor_candidatesForDownload } from "./queries/_hrAdvisor_candidatesForDownload";



const operationUserApi = userApi.injectEndpoints({
    endpoints: (builder) => ({
        // ####################  Queries #################
        getHrAdvisorCandidates: builder.query<IHrAdvisorCandidatesResponse, IHrAdvisorCandidatesVariables>({
            query: (variables: IHrAdvisorCandidatesVariables) => {
                return {
                    url: "",
                    body: hrAdvisor_candidates(variables),
                };
            },
            transformResponse: (response: any) => {
                return response.data.hrAdvisor_candidates;
            },
            //   providesTags: ["operation_company"],
        }),

        getHrAdvisorCandidatesForDownload: builder.query<IHrAdvisorCandidatesResponse, IHrAdvisorCandidatesVariables>({
            query: (variables: IHrAdvisorCandidatesVariables) => {
                return {
                    url: "",
                    body: hrAdvisor_candidatesForDownload(variables),
                };
            },
            transformResponse: (response: any) => {
                return response.data.hrAdvisor_candidates;
            },
        }),
        
        getHrAdvisorCompanies: builder.query<IHrAdvisorCompaniesResponse, IHrAdvisorCompaniesVariables>({
            query: (variables: IHrAdvisorCompaniesVariables) => {
                return {
                    url: "",
                    body: hrAdvisor_companies(variables),
                };
            },
            transformResponse: (response: any) => {
                return response.data.hrAdvisor_companies;
            },
            providesTags: ["hrAdvisor_companies"],
        }),
        getHrAdvisorCompany: builder.query<IHrAdvisorCompanyResponse, IHrAdvisorCompanyVariables>({
            query: (variables: IHrAdvisorCompanyVariables) => {
                return {
                    url: "",
                    body: hrAdvisor_company(variables),
                };
            },
            transformResponse: (response: any) => {
                return response.data.hrAdvisor_company;
            },
            providesTags: ["hrAdvisor_company"],
        }),
        getHrAdvisorDiscoverForms: builder.query<IHrAdvisorDiscoverFormsResponse, IHrAdvisorDiscoverFormsVariables>({
            query: (variables: IHrAdvisorDiscoverFormsVariables) => {
              return {
                url: "",
                body: hrAdvisor_discoverForms(variables),
              };
            },
            transformResponse: (response: any) => {
              return response.data.hrAdvisor_discoverForms;
            },
            providesTags: ["hrAdvisor_discoverForms"],
          }),
      
          getHrAdvisorDiscoverForm: builder.query<IHrAdvisorDiscoverFormResponse, IHrAdvisorDiscoverFormVariables>({
            query: (variables: IHrAdvisorDiscoverFormVariables) => {
              return {
                url: "",
                body: hrAdvisor_discoverForm(variables),
              };
            },
            transformResponse: (response: any) => {
              return response.data.hrAdvisor_discoverForm;
            },
        }),




        // ####################  Mutations #################
        hrAdvisorUpdateDiscoverForm: builder.mutation<IHrAdvisorUpdateDiscoverFormResponse, IHrAdvisorUpdateDiscoverFormVariables>({
            query: (variables: IHrAdvisorUpdateDiscoverFormVariables) => {
              return {
                url: "",
                body: hrAdvisor_updateDiscoverForm(variables),
              };
            },
            transformResponse: (response: any) => {
              return response?.data?.hrAdvisor_updateDiscoverForm;
            },
            invalidatesTags: ["hrAdvisor_discoverForms"],
        }),
          
    }),

    overrideExisting: false,
});

export const {
    // ####################  Queries #################
    useGetHrAdvisorCandidatesQuery,
    useLazyGetHrAdvisorCandidatesQuery,

    useGetHrAdvisorCandidatesForDownloadQuery,
    useLazyGetHrAdvisorCandidatesForDownloadQuery,

    useGetHrAdvisorCompanyQuery,
    useLazyGetHrAdvisorCompanyQuery,
    
    useGetHrAdvisorCompaniesQuery,
    useLazyGetHrAdvisorCompaniesQuery,

    useGetHrAdvisorDiscoverFormQuery,
    useLazyGetHrAdvisorDiscoverFormQuery,
    useGetHrAdvisorDiscoverFormsQuery,
    useLazyGetHrAdvisorDiscoverFormsQuery,

    // ####################  Mutations #################
    useHrAdvisorUpdateDiscoverFormMutation
} = operationUserApi;
