import { IHrAdvisorCandidatesVariables } from "./_hrAdvisor_candidates"


export const hrAdvisor_candidatesForDownload = (variables: IHrAdvisorCandidatesVariables) => {


  const data = JSON.stringify({
    query: `query hrAdvisor_candidatess($pagination: Pagination) {
  hrAdvisor_candidates(pagination: $pagination) {
    count
    data {
      _id
      personalInformation {
        name
        surname
        birthDate
        country
        city
        district
        nationality
        citizenship
        sex
        gender
        genderDetail
        address
        phoneNumber
        profileImageUrl
        backgroundImageUrl
        description
      }
      contact {
        phoneNumber
        phoneNumberVerified
        email
        emailVerified
      }
      education {
        highSchool
        higherEducation {
          higherEducationDegree
          name
          department
          gpa
          startDate
          graduationDate
        }
      }
      certificates {
        name
        type
        issuedDate
        validUntil
        certificateInstitution
      }
      driverLicense {
        status
        issueDate
        licenseType
      }
      languages {
        name
        reading
        writing
        listening
      }
      smoking
      scholarships {
        receivingDate
        type
        institution
      }
      participatedProjects {
        name
        institution
        level
        date
        details
      }
      workExperiencesInMonths
      computerKnowledgeLevel
      programmingLanguages {
        name
        level
      }
      hobbies
      candidateOnboardingStatus
      createdAt
      updatedAt
    }
  }
}
`,
    variables: {
      "pagination": {
        "page": 1,
        "pageSize": 100000
      }
    }
  })

  return data
}