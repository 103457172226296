export interface IAnonymousBlogPostItem {
  _id: string;
  title: string;
  slug: string;
  content: string;
  readingTime: string;
  imageUrl: string;
  targetCategories: Array<string>;
  categories: Array<string>;
  author: string;
  shareImageUrl: string;
  description: string;
  language: string;
  createdAt: string;
  updatedAt: string;
}

export interface IAnonymousBlogPostResponse extends IAnonymousBlogPostItem {}


export interface IAnonymousBlogPostVariables {
  blogPostId?: string | null;
  slug?: string | null;
}

export const anonymous_blogPost = (
  variables: IAnonymousBlogPostVariables
) => {
  const data = JSON.stringify({
    query: `query Anonymous_blogPost($blogPostId: ID, $slug: String) {
      anonymous_blogPost(blogPostId: $blogPostId, slug: $slug) {
        _id
        title
        slug
        content
        readingTime
        imageUrl
        targetCategories
        categories
        author
        shareImageUrl
        description
        language
        createdAt
        updatedAt
      }
    }`,
    variables: variables
  });

  return data;
};
