import {
    Card,
    Stack,
    Table,
    Avatar,
    Dialog,
    Button,
    TableRow,
    TableBody,
    TableCell,
    IconButton,
    Typography,
    TableContainer,
    DialogContent,
    TablePagination,
    DialogTitle,
    DialogActions,
    TextField,
    MenuItem,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { enumFunctions } from 'utils'
import { CreateReminderSection, UserNoteToUserSection } from 'sections';
import { CompanyCandidateCard, CustomTableHeader, ICustomTableHeadCell, Iconify, MuiTransition, TableEmptyData, TableLoading } from 'components'
import { IHrAdvisorCandidatesItem, IHrAdvisorCandidatesVariables, useGetHrAdvisorCandidatesQuery } from '../../hr-advisor/context';
import { ComputerKnowledgeLevelsList, DriverLicenseStatusEnum, DriverLicenseStatusList, GenderEnum, HigherEducationDegreeEnum, HigherEducationDegreesList, LanguageLevelEnum, SexEnum, SexsList } from 'types';
import moment from 'moment';
import { useGetCitiesQuery } from 'context';
import { utils, writeFile } from 'xlsx';


export const CandidatesPage = () => {

    const [variables, setVariables] = useState<IHrAdvisorCandidatesVariables>({
        pagination: {
            page: 1,
            pageSize: 10,
        }
    })

    const { data, isLoading } = useGetHrAdvisorCandidatesQuery(variables)
    const { data: candidatesForDownload } = useGetHrAdvisorCandidatesQuery({
        ...variables,
        pagination: {
            page:1,
            pageSize:100000
        }
    })

    const handleDownloadExcel = () => {
        if (candidatesForDownload?.data) {
            const formattedData = candidatesForDownload.data.map((item) => {
                const cleanedAddress = typeof item.personalInformation?.address === "string"
                    ? item.personalInformation?.address
                        .replace(/[.,:;\/\\]/g, ' ')
                        .replace(/\s+/g, ' ')
                        .replace(/\uFFFE/g, '')
                        .trim()
                    : "";
                return {
                    Id: item._id,
                    Ad: item.personalInformation?.name || '',
                    Soyad: item.personalInformation?.surname || '',
                    DoğumTarihi: moment(item.personalInformation?.birthDate).format('DD/MM/YYYY') || '',
                    Ülke: item.personalInformation?.country || '',
                    Şehir: item.personalInformation?.city || '',
                    İlçe: item.personalInformation?.district || '',
                    Vatandaşlık: item.personalInformation?.citizenship || '',
                    Cinsiyet: item.personalInformation?.sex || '',
                    Adres: cleanedAddress, // Temizlenmiş adres
                    ProfilResmiURL: item.personalInformation?.profileImageUrl || '',
                    Açıklama: item.personalInformation?.description || '',
                    TelefonNumarası: item.personalInformation?.phoneNumber || '',
                    Eposta: item.contact?.email || '',
                    LiseEğitimi: item.education?.highSchool?.join(', ') || '',
                    Yükseköğrenim: item.education?.higherEducation?.map((edu) => `${edu.name}, ${edu.department}, GPA: ${edu.gpa}`).join(', ') || '',
                    Sertifikalar: item.certificates?.map((cert) => `${cert.name}, Tip: ${cert.type}, Kurum: ${cert.certificateInstitution}`).join(', ') || '',
                    EhliyetDurumu: item.driverLicense?.status === 'exist' ? 'Var' : 'Yok',
                    EhliyetTipi: item.driverLicense?.licenseType || '',
                    EhliyetVerilişTarihi: moment(item.driverLicense?.issueDate).format('DD/MM/YYYY') || '',
                    Diller: item.languages?.map((lang) => `${lang.name} (Okuma: ${lang.reading}, Yazma: ${lang.writing}, Dinleme: ${lang.listening})`).join(', ') || '',
                    Sigara: item.smoking !== null ? (item.smoking ? 'Evet' : 'Hayır') : '',
                    Burslar: item.scholarships?.map((sch) => `${sch.institution}, Tip: ${sch.type}`).join(', ') || '',
                    KatıldığıProjeler: item.participatedProjects?.map((proj) => `${proj.name}, Kurum: ${proj.institution}, Seviye: ${proj.level}, Detaylar: ${proj.details}`).join(', ') || '',
                    İşDeneyimiAyOlarak: item.workExperiencesInMonths || '',
                    BilgisayarBilgisi: item.computerKnowledgeLevel || '',
                    ProgramlamaDilleri: item.programmingLanguages?.map((pl) => `${pl.name} (${pl.level})`).join(', ') || '',
                    Hobiler: item.hobbies?.join(', ') || '',
                    OluşturulmaTarihi: moment(item.createdAt).format('DD/MM/YYYY') || '',
                    GüncellenmeTarihi: moment(item.updatedAt).format('DD/MM/YYYY') || '',
                };
            });

            const worksheet = utils.json_to_sheet(formattedData);
            const workbook = utils.book_new();
            utils.book_append_sheet(workbook, worksheet, 'Adaylar');
            writeFile(workbook, 'adaylar_listesi.xlsx');
        }
    };





    const handleChangePage = (e: React.ChangeEvent<unknown>, value: number) => {
        setVariables((prevState) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                page: value + 1
            }
        }))
    }

    const handleChangePageSize = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVariables((prevState) => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                pageSize: Number(e?.target?.value)
            }
        }))
    }


    return (
        <Stack spacing={3}>

            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Typography variant='h1'>
                    Adaylar
                </Typography>
                <Button
                    size='large'
                    variant='contained'
                    color='primary'
                    onClick={handleDownloadExcel}
                >
                    Aday Listesini İndir
                </Button>
            </Stack>

            <HrAdvisorCandidatesTable
                data={data}
                pagination={variables?.pagination}
                loading={isLoading}
                handleChangePageSize={handleChangePageSize}
                handleChangePage={handleChangePage}
                variables={variables}
                setVariables={setVariables}
            />

        </Stack>
    )
}



const HrAdvisorCandidatesTable = ({ data, pagination, loading, handleChangePageSize, handleChangePage, variables, setVariables }: any) => {

    const { data: citiesData } = useGetCitiesQuery({ countryName: "Turkey" })

    const [detailDialog, setDetailDialog] = useState<any>(false)
    const headCells: ICustomTableHeadCell[] = [
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: '#',
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Aday Adı / Resmi',
        },
        {
            id: 'city',
            numeric: false,
            disablePadding: false,
            label: 'Şehir',
        },
        {
            id: 'gender',
            numeric: false,
            disablePadding: false,
            label: 'Cinsiyet',
        },
        {
            id: 'actions',
            numeric: false,
            disablePadding: false,
            label: 'Aksiyonlar',
        },
    ];



    return (
        <>

            <Card sx={{ p: 3 }}>

                <Stack direction="row" spacing={2}>

                    <TextField
                        label="Aday ismine göre ara"
                        value={variables.filter?.personalInformation?.name ?? ""}
                        onChange={(e) => setVariables({
                            ...variables,
                            filter: {
                                ...variables.filter,
                                personalInformation: {
                                    ...variables.filter?.personalInformation,
                                    name: e.target.value as string
                                }
                            }
                        })}
                        sx={{
                            width: '100%',
                            maxWidth: '600px',
                        }}
                    />
                    <TextField
                        select
                        label="Cinsiyet"
                        fullWidth
                        value={variables.filter?.personalInformation?.sex}
                        onChange={(e) => setVariables({
                            ...variables,
                            filter: {
                                ...variables.filter,
                                personalInformation: {
                                    ...variables.filter?.personalInformation,
                                    sex: e.target.value as string
                                }
                            }
                        })}
                    >
                        {SexsList?.map((item: any, index: number) => (
                            <MenuItem key={index} value={item?.value}>
                                {item?.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        select
                        label="Şehir"
                        fullWidth
                        value={variables.filter?.personalInformation?.city ?? ""}
                        onChange={(e) => setVariables({
                            ...variables,
                            filter: {
                                ...variables.filter,
                                personalInformation: {
                                    ...variables.filter?.personalInformation,
                                    city: e.target.value as string
                                }
                            }
                        })}
                    >
                        {citiesData?.data?.map((city, index) => (
                            <MenuItem key={index} value={city?.name}>
                                {city?.name}
                            </MenuItem>
                        ))}

                    </TextField>

                </Stack >
                <Stack direction={"row"} spacing={2} my={3}>
                    <TextField
                        select
                        label="Eğitim Durumu"
                        fullWidth
                        value={variables.filter?.education?.higherEducation?.higherEducationDegree ?? ""}
                        onChange={(e) => setVariables({
                            ...variables,
                            filter: {
                                ...variables.filter,
                                education: {
                                    ...variables.filter?.education,
                                    higherEducation: {
                                        ...variables.filter?.education?.higherEducation,
                                        higherEducationDegree: e.target.value as string
                                    }
                                }
                            }
                        })}
                    >
                        {HigherEducationDegreesList?.map((item: any, index: number) => (
                            <MenuItem key={index} value={item?.value}>
                                {item?.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        select
                        label="Sürücü Belgesi"
                        fullWidth
                        value={variables.filter?.driverLicense?.status ?? ""}
                        onChange={(e) => setVariables({
                            ...variables,
                            filter: {
                                ...variables.filter,
                                driverLicense: {
                                    ...variables.filter?.driverLicense,
                                    status: e.target.value as string
                                }
                            }
                        })}
                    >
                        {DriverLicenseStatusList?.map((item: any, index: number) => (
                            <MenuItem key={index} value={item?.value}>
                                {item?.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        select
                        label="Bilgisayar Bilgisi"
                        fullWidth
                        value={variables.filter?.computerKnowledgeLevel ?? ""}
                        onChange={(e) => setVariables({
                            ...variables,
                            filter: {
                                ...variables.filter,
                                computerKnowledgeLevel: e.target.value as string
                            }
                        })}
                    >
                        {ComputerKnowledgeLevelsList?.map((item: any, index: number) => (
                            <MenuItem key={index} value={item?.value}>
                                {item?.name}
                            </MenuItem>
                        ))}
                    </TextField>

                </Stack>


                <TableContainer>
                    <Table>

                        <CustomTableHeader headCells={headCells} rowCount={data?.data?.length ?? 0} />

                        <TableBody>
                            {
                                !loading ? (
                                    data?.count ? (
                                        data?.data?.map((row: IHrAdvisorCandidatesItem, index: number) => {

                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            const content = {
                                                displayImageUrl: row?.personalInformation?.profileImageUrl,
                                                displayName: row?.personalInformation?.name + ' ' + row?.personalInformation?.surname
                                            }

                                            return (
                                                <TableRow
                                                    key={row?._id ?? index}
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    sx={{ cursor: 'pointer' }}
                                                >

                                                    <TableCell align='center'>
                                                        {index + 1 + ((pagination?.page - 1) * pagination?.pageSize)}
                                                    </TableCell>

                                                    <TableCell component="th" id={labelId} scope="row" sx={{ pl: 2 }}>
                                                        <Stack direction="row" spacing={1} alignItems="center">
                                                            <Avatar
                                                                sx={{ bgcolor: "primary.main", border: "1px solid", borderColor: "primary" }}
                                                                src={row?.personalInformation?.profileImageUrl}
                                                            >
                                                                {row?.personalInformation?.name?.slice(0, 1)}
                                                            </Avatar>
                                                            <Stack direction="column">
                                                                <Typography>
                                                                    {row?.personalInformation?.name} {row?.personalInformation?.surname}
                                                                </Typography>
                                                            </Stack>
                                                        </Stack>
                                                    </TableCell>

                                                    <TableCell
                                                        align="left"
                                                        sx={{
                                                            whiteSpace: "nowrap"
                                                        }}
                                                    >
                                                        {row?.personalInformation?.city}
                                                    </TableCell>

                                                    <TableCell
                                                        align="left"
                                                        sx={{
                                                            whiteSpace: "nowrap"
                                                        }}
                                                    >
                                                        {enumFunctions.findAndDisplayName(SexEnum, row?.personalInformation?.sex)}
                                                    </TableCell>

                                                    <TableCell align="left" >
                                                        <Stack direction="row" spacing={1}>
                                                            <UserNoteToUserSection targetId={row?._id} />

                                                            <IconButton
                                                                sx={{ bgcolor: 'secondary.light' }}
                                                                onClick={() => setDetailDialog(row)}
                                                                title="Detayı gör."
                                                            >
                                                                <Iconify icon="ph:eye-fill" sx={{ color: 'primary.main' }} />
                                                            </IconButton>

                                                            <CreateReminderSection user={row} content={content} />
                                                        </Stack>
                                                    </TableCell>

                                                </TableRow>
                                            );
                                        })
                                    ) : (
                                        <TableEmptyData colspan={headCells.length} text="Hiç bir aday bulunamadı!" />
                                    )
                                ) : (
                                    <TableLoading colspan={headCells.length} />
                                )
                            }
                        </TableBody>

                    </Table>
                </TableContainer>

                <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 20]} // içindeki numaraları dropdown olarak gösteriyor
                    count={data?.count ?? 0} // toplam eleman sayısı
                    rowsPerPage={pagination.pageSize ?? 1} // sayfadaki eleman sayısı
                    page={(pagination.page ?? 1) - 1} // kaçıncı sayfa olduğunu gösteriyor, 0 ilk sayfa

                    labelRowsPerPage="Sayfa başına gösterim"
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangePageSize}
                />

            </Card >


            <Dialog
                open={!!detailDialog}
                TransitionComponent={MuiTransition}
                keepMounted
                onClose={() => setDetailDialog(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="simple-dialog-title">Aday Detayı</DialogTitle>
                <DialogContent sx={{ width: { xs: '100%', sm: "600px" } }}>
                    <CompanyCandidateCard candidateDetail={detailDialog} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setDetailDialog(null)
                    }}
                        color="primary" variant='outlined'>
                        Kapat
                    </Button>
                </DialogActions>

                {/* <DialogContent>
                    <Stack spacing={2} py={3}>

                        <Stack spacing={2} direction={"row"} alignItems={"center"} bgcolor={"secondary.lighter"} p={2} borderRadius={2}>
                            <Avatar alt={detailDialog?._id} src={detailDialog?.personalInformation?.profileImageUrl} sx={{ width: 80, height: 80 }} />

                            <Stack spacing={0.5}>
                                <Typography variant={"h6"}>
                                    {detailDialog?.personalInformation?.name} {detailDialog?.personalInformation?.surname}
                                </Typography>
                                <Typography fontSize={"small"}>
                                    📞 <a href={`tel:${detailDialog?.personalInformation?.phoneNumber}`} style={{ color: 'black', fontWeight: "bold" }}>
                                        {detailDialog?.personalInformation?.phoneNumber}
                                    </a>
                                </Typography>
                                <Typography fontSize={"small"}>
                                    📧 <a href={`mailto:${detailDialog?.contact?.email}`} style={{ color: 'black', fontWeight: "bold" }}>
                                        {detailDialog?.contact?.email
                                        }</a>
                                </Typography>
                            </Stack>
                        </Stack>

                        <Stack direction={{ xs: 'column', md: 'row' }} spacing={1} justifyContent="space-between" alignItems="flex-start">
                            <Stack spacing={1} width={{ xs: '100%', sm: "300px" }} >
                                <Typography fontWeight={"bold"}>Kişisel Bilgiler</Typography>
                                <Typography fontSize={"small"}><strong>Doğum Tarihi: </strong> {moment(detailDialog?.personalInformation?.birthDate).format("DD MMMM YYYY")}</Typography>
                                <Typography fontSize={"small"}><strong>Cinsiyet: </strong> {enumFunctions.findAndDisplayName(SexEnum, detailDialog?.personalInformation?.sex)}</Typography>
                                <Typography fontSize={"small"}><strong>Cinsel Tercih:</strong> {enumFunctions.findAndDisplayName(GenderEnum, detailDialog?.personalInformation?.gender)} {enumFunctions.findAndDisplayName(GenderEnum, detailDialog?.personalInformation?.genderDetail)}  </Typography>
                                <Typography fontSize={"small"}><strong>Uyruk: </strong> {detailDialog?.personalInformation?.nationality}</Typography>
                                <Typography fontSize={"small"}><strong>Farklı Vatandaşlık: </strong> {detailDialog?.personalInformation?.citizenship}</Typography>
                                <Typography fontSize={"small"}><strong>Adres: </strong> {detailDialog?.personalInformation?.district}, {detailDialog?.personalInformation?.city} | {detailDialog?.personalInformation?.address}</Typography>
                                <Typography fontSize={"small"}><strong>Açıklama: </strong> {detailDialog?.personalInformation?.description}</Typography>
                                <Typography fontSize={"small"}><strong>Sigara Kullanımı: </strong>{detailDialog?.smoking ? "İçiyor" : "İçmiyor"}</Typography>

                                <Stack spacing={1}>
                                    <Typography fontWeight={"bold"}>Eğitim Bilgileri</Typography>
                                    <Typography fontSize={"small"}><strong>Lise: </strong> {detailDialog?.education?.highSchool?.join(', ')}</Typography>
                                    <Typography fontSize={"small"}><strong>Yükseköğrenim: </strong> {detailDialog?.education?.higherEducation?.map((degree: any) => `${degree?.name} - ${degree?.department} - ${enumFunctions.findAndDisplayName(HigherEducationDegreeEnum, degree?.higherEducationDegree)} - ${degree?.gpa}`).join('<br/> ')}</Typography>
                                </Stack>

                                <Stack spacing={1}>
                                    <Typography fontWeight={"bold"}>Sertifikalar</Typography>
                                    {
                                        detailDialog?.certificates?.map((certificate: any, index: number) => (
                                            <Typography fontSize={"small"} key={index}>
                                                <strong>{certificate?.name}, </strong> {certificate?.type} - {moment(certificate?.issuedDate).format("MM.YYYY")} - {moment(certificate?.validUntil).format("MM.YYYY")} - {certificate?.certificateInstitution}
                                            </Typography>
                                        ))
                                    }
                                </Stack>
                            </Stack>

                            <Divider orientation="vertical" flexItem />

                            <Stack spacing={1} width={{ xs: '100%', sm: "300px" }}>
                                <Stack spacing={1}>
                                    <Typography fontWeight={"bold"}>Bildiği Diller</Typography>
                                    {
                                        detailDialog?.languages?.map((language: any, index: number) => (
                                            <Typography fontSize={"small"} key={index}>
                                                <strong>{language?.name?.toUpperCase()}:</strong> Okuma: {enumFunctions.findAndDisplayName(LanguageLevelEnum, language?.reading)}, Yazma: {enumFunctions.findAndDisplayName(LanguageLevelEnum, language?.writing)}, Dinleme: {enumFunctions.findAndDisplayName(LanguageLevelEnum, language?.listening)}
                                            </Typography>
                                        ))
                                    }
                                </Stack>

                                <Stack spacing={1}>
                                    <Typography fontWeight={"bold"}>Sürücü Belgesi</Typography>
                                    <Typography fontSize={"small"}>{enumFunctions.findAndDisplayName(DriverLicenseStatusEnum, detailDialog?.driverLicense?.status)}, {detailDialog?.driverLicense?.licenseType} sınıfı, {moment(detailDialog?.driverLicense?.issueDate).format("MM.YYYY")}</Typography>
                                </Stack>

                                <Stack spacing={1}>
                                    <Typography fontWeight={"bold"}>Burs ve Burslar</Typography>
                                    {detailDialog?.scholarships?.map((scholarship: any, index: number) => (
                                        <Typography fontSize={"small"} key={index}>{moment(scholarship?.receivingDate).format("MM.YYYY")} - {scholarship?.type} - {scholarship?.institution}</Typography>
                                    ))}
                                </Stack>
                                <Stack spacing={1}>
                                    <Typography fontWeight={"bold"}>Katıldığı Projeler</Typography>
                                    {detailDialog?.participatedProjects?.map((project: any, index: number) => (
                                        <Typography fontSize={"small"} key={index}>{project?.name} - {project?.institution} - {project?.level} - {moment(project?.date).format("MM.YYYY")} - {project?.details}</Typography>
                                    ))}
                                </Stack>
                                <Stack spacing={1}>
                                    <Typography fontWeight={"bold"}>Hobileri</Typography>
                                    {detailDialog?.hobbies?.map((hobby: any, index: number) => (
                                        <Typography fontSize={"small"} key={index}>{hobby}</Typography>
                                    ))}
                                </Stack>
                            </Stack>
                        </Stack>

                        <Stack direction="row" justifyContent="space-between" gap={3}>
                            <Button
                                onClick={() => setDetailDialog(false)}
                                variant='outlined'
                                color="primary"
                            >
                                Kapat
                            </Button>


                        </Stack>

                    </Stack>
                </DialogContent> */}
            </Dialog>


        </>
    )
}
